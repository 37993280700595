import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import LinkText from '../common/LinkText'
import logo from '../../static/lowr-black.png'
import { getLoginConfig, getUser } from '../../state/user/selectors'
import { fetchLoginConfig, loginSuccess } from '../../state/user/actions'
import { Colors } from '../../themes'
import { SwitcherLanguageIcon } from '../common/Svg'
import LanguageModal from '../LanguageModal'

interface IProps {
    title: string | React.ReactNode
    children: React.ReactNode
    bottomNavStyles?: object
}

export default function AuthContainer({ children, title, bottomNavStyles = {} }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loginConfig, loginConfigLoading } = useSelector(getLoginConfig)
    const domain = window?.location?.host
    const { token } = useSelector(getUser)
    const [t] = useTranslation('global')
    const [languageShowModal, setLanguageModal] = useState(false)
    const userLanguage = navigator.language.split('-')[0]

    const userDefaultLanguage = loginConfig?.languages?.find((item) => item.language.shortName === userLanguage)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString && JSON.parse(languageDataString)

    useEffect(() => {
        if (token && token.token) {
            navigate('/')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(fetchLoginConfig(domain))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (loginConfig?.authorize) {
            dispatch(loginSuccess(loginConfig.authorize))
            navigate('/')
        }

        // eslint-disable-next-line
    }, [loginConfig])

    useEffect(() => {
        if (loginConfig?.languages && !languageData) {
            i18next.changeLanguage(userDefaultLanguage?.language?.shortName ? userLanguage : 'en')
            localStorage.setItem('browserLanguage', JSON.stringify(userDefaultLanguage?.language?.shortName ? userLanguage : 'en'))
        }
    }, [userLanguage, loginConfig, languageData])

    if (loginConfigLoading || loginConfig?.authorize) return <div />

    return (
        <div style={{ borderColor: loginConfig?.whiteLabeling?.avocado || Colors.avocado, backgroundColor: loginConfig?.whiteLabeling?.pistachio || Colors.pistachio }} className="w-full min-h-screen justify-center flex px-4 pt-[41px] pb-[37px] border-[24px] border-solid">
            <div className="flex flex-col">
                {loginConfig?.showLogoOnAuth && (
                    <img src={logo} className={css(styles.logo)} alt="" />
                )}

                <h1 style={{ color: loginConfig?.whiteLabeling?.black || Colors.black }} className="font-medium text-center font tracking-[-.02em] text-32 mt-[75px] mb-[24px]">
                    {title}
                </h1>

                <div className={css(styles.content)} style={{ margin: '0 auto' }}>
                    {children}
                </div>

                <div style={{ margin: 'auto', marginBottom: 0, ...bottomNavStyles }} className={classNames('flex justify-between items-end', css(styles.content))}>
                    <LinkText className="mr-1 text-center" text={t('register.terms-of-service')}
                        to="https://www.lowr.co/terms-of-service" hasTarget />
                    <LinkText className="mr-1 text-center" text={t('register.privacy-policy')}
                        to="https://www.lowr.co/privacy-policy" hasTarget />
                    <LinkText className="text-center underline font-semibold pointer mt-6"
                        text={t('register.contact-us')}
                        to="https://www.lowr.co/contact-us"
                        hasTarget />

                    {loginConfig?.languages && loginConfig?.languages?.length > 1 && (
                        <div className="mr-2.5 cursor-pointer mt-1" onClick={() => setLanguageModal(true)}>
                            <SwitcherLanguageIcon color={loginConfig?.whiteLabeling?.black} />
                        </div>
                    )}
                </div>

                {loginConfig ? (
                    <LanguageModal loginConfig={loginConfig} onClose={() => setLanguageModal(false)}
                        isShown={languageShowModal} />
                ) : null}
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    logo: {
        width: 136,
        height: 40.22,
        margin: '0 auto',
    },
    content: {
        width: 343,
        '@media (max-width: 460px)': {
            width: '90%',
        },
    },
})
