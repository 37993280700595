import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { ArrowTravel, DefaultHeartIcon, DismissIcon } from '../../components/common/Svg'
import GoogleAutoComplete from '../../components/GoogleAutoComplete'
import { Radio } from '../../components/common/Radio'
import Button from '../../components/common/Button'
import { getSavedPlacesList, getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { Colors } from '../../themes'
import { Context } from '../../components/common/BaseContext'
import { commuteStartEndList, journeyTypesList, travelSelector } from '../../state/travel/selectors'
import { changeCommuteDestination,
    fetchJourneyTypes,
    fetchTransport,
    fetchTransportModes,
    handleCommuteStartEnd } from '../../state/travel/actions'
import { JourneyTypes } from '../../types/data'
import Layout from '../../components/layout/Layout'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { getLocationByPlaceId } from '../../utils/travel'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { travelDate } from '../../utils/date'
import Switcher from '../../components/common/Switcher'

export default function LogTravelStartEnd() {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { gameList } = useSelector(travelSelector)
    const impact = location?.state?.impact
    const today = new Date()
    const returnTravel = location?.state?.returnTravel
    const isSwitched = location?.state?.isSwitched
    const { user } = useSelector(getUser)
    const { navbarHeight, screenWidth, isEmbedded } = useContext(Context)
    const [t] = useTranslation('global')
    const { journeyTypes } = useSelector(journeyTypesList)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [selectedValue, setSelectedValue] = useState<string | number | undefined>('')
    const [startPlace, setStartPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [endPlace, setEndPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [loading, setLoading] = useState<boolean>(false)
    const [defaultChecked, setDefaultChecked] = useState<boolean>(false)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const { commuteStartEnd } = useSelector(commuteStartEndList)

    const isDisabled = user?.company?.travelTrackingType !== 'event' ? !(startPlace.placeId && endPlace.placeId) : !startPlace.placeId
    const defaultItemId = journeyTypes?.find((item: JourneyTypes) => item?.state === 'default')
    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    // const onContinue = startPlace?.name && endPlace.name && (startPlace?.name !== outboundData?.startPlaceName || endPlace?.name !== outboundData?.endPlaceName) && !disableAutoSubmit
    const { savedPlaces } = useSelector(getSavedPlacesList)

    const endRef = useRef<any>()
    const buttonRef = useRef<any>()

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Log travel start end' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    useEffect(() => {
        if (user?.company?.isTravelTypeEvent && startPlace.placeId) {
            onSubmit()
        } else if (returnTravel && endPlace.placeId && startPlace.placeId) {
            onSubmit()
        }

    // eslint-disable-next-line
}, [endPlace, startPlace]);

    useEffect(() => {
        if (defaultItemId?.id) setSelectedValue(defaultItemId?.id)
    }, [defaultItemId])

    useEffect(() => {
        const firstInput = document.querySelector('input')

        if (firstInput) {
            firstInput.focus()
        }
    }, [])

    const initStoredDataString = localStorage.getItem('travelData')
    const initStoredData = initStoredDataString ? JSON.parse(initStoredDataString) : {}

    const handleRadioChange = (value: string | number) => {
        setSelectedValue(value)
    }

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const game = initStoredData.gameData

    const gamePlaceId = game && game[0] ? game[0].item.placeId : null
    const gamePlaceName = game && game[0] ? game[0].item.place : null

    const onRestartLoad = useCallback(() => {
        dispatch(fetchJourneyTypes())
        dispatch(fetchTransport())
        dispatch(fetchTransportModes())
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const onSubmit = async () => {
        if (isDisabled) return

        if (isChecked) {
            navigate('/log/log-travel-date', { state: { dateValues: outboundData.gameData, isChecked, startPlace, impact, endPlace, selectedValue } })

            return
        }

        if (returnTravel) {
            dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, {
                returnJourneyStartPlaceId: startPlace?.placeId,
                returnJourneyStartPlaceName: startPlace.name,
                returnJourneyStartPlaceLon: startPlace.lon,
                returnJourneyStartPlaceLat: startPlace.lat,
                returnJourneyEndPlaceId: endPlace.placeId,
                returnJourneyEndPlaceName: endPlace.name,
                returnJourneyEndPlaceLon: endPlace.lon,
                returnJourneyEndPlaceLat: endPlace.lat,
                deleteReturnLegs: true,
            }, () => {
                navigate('/log/track-travel-modality', { state: { impact, goingBackCommute: true, prevUrl: location?.state?.prevUrl, travelName: startPlace?.name } })
            }))

            return
        }

        const storedDataString = localStorage.getItem('travelData')
        const storedData = storedDataString ? JSON.parse(storedDataString) : {}

        setLoading(true)

        dispatch(
            handleCommuteStartEnd(
                storedData,
                outboundData?.backendCommuteId || commuteStartEnd?.id,
                startPlace,
                endPlace,
                selectedValue ? Number(selectedValue) : undefined,
                () => {
                    if (game && isSwitched) {
                        navigate('/log/overnight-stay', {
                            state: {
                                impact,
                                prevUrl: location?.state?.prevUrl,
                                travelName: startPlace?.name,
                                isSwitched,
                            },
                        })
                    } else {
                        navigate('/log/track-travel-modality', {
                            state: {
                                impact,
                                prevUrl: location?.state?.prevUrl,
                                travelName: startPlace?.name,
                            },
                        })
                    }

                    setLoading(false)
                },
            ),
        )
    }

    useEffect(() => {
        handleEndPlaceIfEvent(returnTravel ? setStartPlace : setEndPlace)

        if (outboundData.startPlaceId && !returnTravel) {
            setStartPlace({
                lat: outboundData.startPlaceLat,
                lon: outboundData.startPlaceLon,
                placeId: outboundData.startPlaceId,
                name: outboundData.startPlaceName,
            })
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (returnTravel) {
            setStartPlace({
                lat: outboundData.endPlaceLat,
                lon: outboundData.endPlaceLon,
                placeId: outboundData.endPlaceId,
                name: outboundData.endPlaceName,
            })
        }
        // eslint-disable-next-line
    }, [returnTravel])

    const handleEndPlaceIfEvent = async (setPlace: (data: typeof endPlace) => void) => {
        // if (!game.length || game.length > 1) return

        if (!gamePlaceId || !gamePlaceName) {
            if (outboundData.endPlaceId && !returnTravel) {
                setPlace({
                    lat: outboundData.endPlaceLat,
                    lon: outboundData.endPlaceLon,
                    placeId: outboundData.endPlaceId,
                    name: outboundData.endPlaceName,
                })
            }

            return
        }

        setPlace({
            placeId: '',
            name: '',
            lat: '',
            lon: '',
        })

        const locationData = await getLocationByPlaceId(gamePlaceId)

        if (setPlace) {
            setPlace({
                placeId: gamePlaceId,
                name: gamePlaceName,
                ...locationData,
            })
        }
    }

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')
    const isGameNotExists = !initStoredData.gameIds?.length
    const minDate = outboundData.date?.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    if (!outboundData.date) {
        navigate('/log')
        return <div />
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false}>
            <div className="w-full h-screen">
                <style>
                    {`
                        .radio-style input[type='radio'] {
                            outline: none;
                            appearance: none;
                            width: 24px;
                            height: 24px;
                            border: 1px solid #417339FF;
                            padding: 3px; /* Control the space between border and background */
                        }

                        .btnd:active,
                        .btnd:focus,
                        .btnd:focus:active {
                            background-image: none;
                            outline: 0;
                            box-shadow: none;
                        }

                        .radio-style input[type="radio"]:checked {
                            width: 24px;
                            height: 24px;
                            border-color: #417339FF;
                            background: ${(whiteLabeling as any)?.rocket} content-box;
                        }
                    `}
                </style>

                <div style={{ marginTop: navbarHeight, left: '50%', transform: 'translateX(-50%)' }}
                    className="fixed top-0 left-0 w-full z-10 bg-white max-w-[1440px]">
                    {sponsoredBlock && <div style={{ }} className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                    <div className="flex justify-between items-center h-[53px] pr-2 mobile:mx-[42px] mx-4">
                        <div onClick={() => navigate(-1)} className="flex justify-end cursor-pointer">
                            <ArrowTravel width={22} height={22} />
                        </div>

                        <div onClick={() => setShowQuizQuitOverlayModal(true)}
                            className="flex justify-end cursor-pointer">
                            <DismissIcon width={18} height={18} />
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : !sponsoredBlock ? navbarHeight : 125 }} className="h-screen flex flex-col items-center mobile:px-[42px] px-4">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        {!returnTravel && (
                            <h1
                                className="heading-uppercase text-22 font tracking-tight font-semibold text-cavolo mt-2 mb-7">
                                {t('log.log-a-new-journey')}
                            </h1>
                        )}

                        {!returnTravel && (
                            <div>
                                <div className="flex items-center justify-between mt-4 mb-[10px]">
                                    <h1 className="font-medium not-heading-font text-cavolo text-16">
                                        {t(travelDate(today) >= minDate ? 'log.where-did-you-start-your-journey?' : 'log.FUTURE|where-did-you-start-your-journey?')}
                                    </h1>

                                    {/* <div */}
                                    {/*    className="flex items-end flex-col-reverse justify-end mx-[5px] mt-[5px] cursor-pointer" */}
                                    {/*    onClick={() => setShowTooltipFirst(!showTooltipFirst)}> */}
                                    {/*    <QuestionIcon /> */}

                                    {/*    {showTooltipFirst && ( */}
                                    {/*        <div */}
                                    {/*            className={cn('mt-[30px] bg-sea text-black tracking-[-.01em] leading-[150%] px-[19px] py-[16px] text-14 font-normal rounded-lg absolute mobile:w-[320px] z-10', css(screenWidth <= 768 ? styles.textActive : styles.textDefault))}> */}
                                    {/*            {t(`log.${game?.isFuture ? 'FUTURE|' : ''}e.g.-your-home,-place-of-work,-or-where-you-were-staying,-not-the-station-where-you-got-on-the-train`)} */}
                                    {/*        </div> */}
                                    {/*    )} */}
                                    {/* </div> */}
                                </div>

                                <h1 className="text-14 text-grey01 font-normal my-2">
                                    {t('log.first-help-text-for-log-travel-start-end')}
                                </h1>

                                {isEmpty(savedPlaces) && user?.company?.showSavedPlaces ? (
                                    <div className="flex items-center mb-2.5">
                                        <DefaultHeartIcon />

                                        <h1 className="ml-2.5 text-12 text-grey01">
                                            {t('savedPlaces.tap-the-heart-to-save-a-place-for-future-use')}
                                        </h1>
                                    </div>
                                ) : null}

                                <GoogleAutoComplete onChange={() => {
                                    if (endRef.current) {
                                        endRef.current?.focus()
                                    }
                                }} placeholder={t('log.start-typing')} defaultValue={startPlace} setPlace={setStartPlace} />
                            </div>
                        )}

                        {(isGameNotExists || returnTravel) && (
                            <div className="mt-[28px]">
                                <div className="flex items-center justify-between mt-4 mb-[10px]">
                                    <h1 className="font-medium not-heading-font text-cavolo text-16">
                                        {t(travelDate(today) >= minDate ? 'log.where-did-you-end-your-journey?' : 'log.FUTURE|where-did-you-end-your-journey?')}
                                    </h1>

                                    {/* <div */}
                                    {/*    className="flex items-end flex-col-reverse justify-end mx-[5px] mt-[5px] cursor-pointer" */}
                                    {/*    onClick={() => setShowTooltipSecond(!showTooltipSecond)}> */}
                                    {/*    <QuestionIcon /> */}

                                    {/*    {showTooltipSecond && ( */}
                                    {/*        <div */}
                                    {/*            className={cn('mt-[30px] bg-[#62BFF4] text-[#00362B] tracking-[-.01em] leading-[150%] px-[19px] py-[16px] text-14 font-normal rounded-lg absolute mobile:w-[320px] z-10', css(screenWidth <= 768 ? styles.textActive : styles.textDefault))}> */}
                                    {/*            {t(`log.${game?.isFuture ? 'FUTURE|' : ''}your-final-destination,-not-where-you-parked-the-car-or-the-station-where-you-got-off-the-train`)} */}
                                    {/*        </div> */}
                                    {/*    )} */}
                                    {/* </div> */}
                                </div>

                                <h1 className="text-14 text-grey01 font-normal my-2">
                                    {t('log.second-help-text-for-log-travel-start-end')}
                                </h1>

                                <GoogleAutoComplete onChange={() => {
                                    buttonRef.current.focus()
                                }} selectRef={endRef} placeholder={t('log.start-typing')} defaultValue={endPlace} setPlace={setEndPlace} />
                            </div>
                        )}

                        {!isSwitched && user?.company?.autoForwardIfSingleEvent && gameList.filter((i) => !i.isDisabled).length === 1 ? (
                            <Switcher isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} title={t('log.i-stayed-away-from-home-overnight-on-this-trip')} />
                        ) : (
                            <div />
                        )}

                        {isEmpty(journeyTypes) || returnTravel ? (
                            <div />
                        ) : (
                            <>
                                <h1 className="not-heading-font font-medium text-black text-16 mt-[28px]">
                                    {travelDate(today) >= minDate ? user?.company?.pastJourneyTypeLabel : user?.company?.futureJourneyTypeLabel}
                                </h1>

                                <div className="tablet:mt-[16px] mt-[10px]" onClick={() => setDefaultChecked(false)}>
                                    {journeyTypes?.map((item, index) => (
                                        <Radio
                                            inputClassName="mb-4 tablet:mb-[20px]"
                                            key={item.id}
                                            label={item?.name}
                                            // @ts-ignore
                                            value={item?.id}
                                            labelClassName="text-black tablet:text-16 text-14 mb-4 tablet:mb-[20px]"
                                            checked={defaultChecked ? index + 1 === defaultItemId?.id : Number(selectedValue) === item.id}
                                            onChange={handleRadioChange}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {/* {((startPlace?.name === outboundData?.startPlaceName || endPlace?.name === outboundData?.endPlaceName) || journeyTypes.length) && ( */}
                <div
                    className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                    style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                    <div
                        className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                        <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                        <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                            <Button
                                buttonRef={(ref: any) => {
                                    buttonRef.current = ref
                                }}
                                loading={loading}
                                onClick={onSubmit}
                                style={isDisabled ? disabledStyles : undefined}
                                className="absolute bottom-8 duration-100 not-heading-font"
                                text={t('global.continue')} />
                        </div>
                    </div>
                </div>
                {/* )} */}

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => navigate('/')}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />
            </div>
        </Layout>
    )
}
