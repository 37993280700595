import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import ReactGA from 'react-ga4'
import Layout from '../components/layout/Layout'
import PerkOfTheDay from '../components/perk/PerkOfTheDay'
import FactOfTheDay from '../components/FactOfTheDay'
import PerkCard from '../components/perk/PerkCard'
import PerkOfTheMonth from '../components/perk/PerkOfTheMonth'
import DiscoverCard from '../components/article/DiscoverCard'
import HomeRecipeWeLoveCard from '../components/recipe/HomeRecipeWeLoveCard'
import RecipeCard from '../components/recipe/RecipeCard'
import { fetchHomePage } from '../state/home/actions'
import { getHomePageLists } from '../state/home/selectors'
import { getPerkLists } from '../state/perks/selectors'
import PerkPartners from '../components/perk/PerkPartners'
import { IDashboardColumn, IHomePage, IPerks } from '../types/data'
import PrizeDrawCard from '../components/PrizeDrawCard'
import { getPrizeDraw } from '../state/prize/selectors'
import { dash } from '../utils/date'
import '../static/index.css'
import ChallengeCardMini from '../components/challenge/ChallengeCardMini'
import { Context } from '../components/common/BaseContext'
import QuizCard from '../components/quiz/QuizCard'
import SpecificContentCard from '../components/article/specificContent/SpecificContentCard'
import PledgesCard from '../components/pledges/PledgesCard'
import MyProgress from '../components/account/MyProgress'
import MyStats from '../components/account/MyStats'
import MyAchievements from '../components/account/MyAchievements'
import ContentBlockComponent from '../components/ContentBlockComponent'
import Loader from '../components/common/Loader'
import { LeaderboardBadge, UserDefaultIcon, UserIcon } from '../components/common/Svg'
import { getLeaderboard, getMyRangeList } from '../state/leaderboard/selectors'
import { getUser, getWhiteLabelingList } from '../state/user/selectors'
import { fetchMyRange } from '../state/leaderboard/actions'
import { sortStickyDashboard } from '../utils/helpers'
import SponsoredByBlock from '../components/SponsoredByBlock'
import { fetchBadgeTasks } from '../state/user/actions'
import { fetchPerksBrand } from '../state/perks/actions'

const data = [undefined, undefined, undefined, undefined, undefined, undefined]
type COLUMN_NAMES = 'Left' | 'Right' | 'Main'

export default function Dashboard() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { homePage, sponsorshipBlocks } = useSelector(getHomePageLists)
    const { leaderboard, loading: leaderboardLoading } = useSelector(getLeaderboard)
    const { perksBrand } = useSelector(getPerkLists)
    const { prizeDraw } = useSelector(getPrizeDraw)
    const { myRangeBelowTwo } = useSelector(getMyRangeList)
    const { screenWidth } = useContext(Context)
    const { user, token } = useSelector(getUser)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { navbarHeight, screenHeight } = useContext(Context)
    const [t] = useTranslation('global')
    const [showSwitch, setShowSwitch] = useState<number>(0)

    const leaderboards = leaderboard.leaderboards.find((item) => item.id === myRangeBelowTwo?.currentLeaderboard)

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const endsAtDate = new Date(prizeDraw.endsAt)
    endsAtDate.setHours(0, 0, 0, 0)

    const timeDifference = endsAtDate.getTime() - today.getTime()
    const daysUntilEndsAt = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

    const sponsoredBlock = sponsorshipBlocks ? sponsorshipBlocks?.find((i) => i.siteSection === 'Home') : null

    const onRestartLoad = useCallback(() => {
        if (token && token.token) {
            dispatch(fetchHomePage)
            dispatch(fetchMyRange())
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const firstButton = document.querySelector('.homepage-content button') as HTMLButtonElement

        if (firstButton) {
            firstButton.focus()
            firstButton.style.outline = 'none'
        }

        const handleKeyDown = (event: any) => {
            if (event.key === 'Tab') {
                firstButton.style.removeProperty('outline')
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [homePage])

    useEffect(() => {
        if (homePage.myAchievementsLeft || homePage.myAchievementsRight || homePage.myAchievementsMain) {
            dispatch(fetchBadgeTasks)
        }

        // eslint-disable-next-line
    }, [homePage.myAchievementsLeft, homePage.myAchievementsRight, homePage.myAchievementsMain])

    useEffect(() => {
        if (homePage?.perkPartnersRight || homePage?.perkPartnersLeft || homePage?.perkPartnersMain) {
            dispatch(fetchPerksBrand)
        }

        // eslint-disable-next-line
    }, [homePage?.perkPartnersRight, homePage?.perkPartnersLeft, homePage?.perkPartnersMain])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const percentage = Math.trunc(
        // eslint-disable-next-line no-unsafe-optional-chaining
        (homePage?.challenge?.loggedDaysCount * 100) / homePage?.challenge?.challengeDuration,
    )

    const navigateChallengeDetail = (challengeId: number) => {
        navigate(`/lower/pledges/challenge-detail/${challengeId}`)
    }

    const styles = StyleSheet.create({
        accountInfoContainer: {
            '@media (max-width: 767px)': {
                height: 'auto',
                paddingTop: navbarHeight,
            },
        },
        dashboardContent: {
            '@media (max-width: 767px)': {
                paddingTop: user?.company?.menuPosition === 'Bottom' ? 0 : (navbarHeight + (sponsoredBlock ? 0 : 20)),
            },
        },
        dashboardContainer: {
            '@media (max-width: 911px) and (min-width: 768px)': {
                marginTop: 40,
                marginLeft: 40,
                marginRight: 40,
            },

            '@media (max-width: 767px)': {
                marginLeft: 0,
                marginRight: 0,
                display: 'block',
            },
        },
    })

    const navigatePerkDetail = (detailId: number) => {
        navigate(`/lower/swaps/${detailId}`)
    }

    const switchData = myRangeBelowTwo?.leaderboards?.map((item) => ({
        id: item?.id,
        name: item?.entry?.name || 'Individual',
        icon: item?.isUserLeaderboard ? UserIcon : LeaderboardBadge,
        image: item?.entry?.image,
        isUserLeaderboard: item?.isUserLeaderboard,
    }))

    const switchSelectorFunc = (index: number, id: number | string) => {
        setShowSwitch(index)
        dispatch(fetchMyRange({ leaderboard: id }))
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [homePage])

    /** ** Navigation perk view all *** */
    const navigatePerkViewAll = () => {
        navigate(`/dashboard/swaps-section/${dash(homePage?.perkCollection?.name)}/view-all`)
    }

    const navigateRecipeViewAll = () => {
        navigate(`/dashboard/recipes-section/${dash(homePage?.recipeCollection?.name)}/view-all`)
    }

    const navigatePledgesViewAll = () => {
        navigate(`/dashboard/pledges-section/${dash(homePage?.pledgeCollection?.title)}/view-all`, { state: { pledges: homePage.pledgeCollection } })
    }

    const columnItems = (homepage:IHomePage, columnName: COLUMN_NAMES): IDashboardColumn[] => {
        const column = [
            {
                position: homepage[`myProgress${columnName}`],
                positionLeft: homepage.myProgressLeft,
                positionRight: homepage.myProgressRight,
                component: user?.company?.gamification && prizeDraw?.showProgressBar ? <MyProgress /> : null,
            },
            {
                position: homepage[`userPicture${columnName}`],
                positionLeft: homepage.userPictureLeft,
                positionRight: homepage.userPictureRight,
                component: (
                    <div className="flex flex-col justify-center items-center max-mobile:px-4">
                        {!user.uploadedImage && !user.socialImage ? (
                            <div className="mb-[8px]">
                                <UserDefaultIcon width={100} height={100} />
                            </div>
                        ) : user.uploadedImage ? (
                            <img
                                className="rounded-full object-cover w-[100px] h-[100px] mb-[8px]"
                                src={user.uploadedImage}
                                alt=""
                            />
                        ) : (
                            <img
                                className="rounded-full object-cover w-[100px] h-[100px] mb-[8px]"
                                src={user.socialImage}
                                alt=""
                            />
                        )}

                        {user?.company?.gamification && !isEmpty(prizeDraw) ? (
                            <div className="font-medium text-[22px] not-heading-font tracking-[-0.02rem] text-black">
                                {t('home.keep-going')}, {user.firstName}!
                            </div>
                        ) : null}
                    </div>
                ),
            },
            {
                position: homepage[`myStats${columnName}`],
                positionLeft: homepage.myStatsLeft,
                positionRight: homepage.myStatsRight,
                component: user?.company?.gamification && prizeDraw?.showProgressBar ? <MyStats /> : null,
            },
            {
                position: homepage[`myAchievements${columnName}`],
                positionLeft: homepage.myAchievementsLeft,
                positionRight: homepage.myAchievementsRight,
                component: user?.company?.gamification ? <MyAchievements /> : null,
            },
            {
                positionLeft: homepage.perkOfTheDayLeft,
                positionRight: homepage.perkOfTheDayRight,
                position: homepage?.perkOfTheDay ? homepage[`perkOfTheDay${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                            {t('home.swap-of-the-day')}
                        </span>

                        <PerkOfTheDay item={homepage?.perkOfTheDay} type="home" />
                    </div>
                ),
            },
            {
                positionLeft: homepage.factOfTheDayLeft,
                positionRight: homepage.factOfTheDayRight,
                position: homePage?.factOfTheDay?.body ? homepage[`factOfTheDay${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <FactOfTheDay item={homePage?.factOfTheDay} />
                    </div>
                ),
            },
            {
                positionLeft: homepage.perkCollectionLeft,
                positionRight: homepage.perkCollectionRight,
                position: homePage?.perkCollection?.perks ? homepage[`perkCollection${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <div className="flex items-end justify-between">
                            <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                                {homePage?.perkCollection?.name}
                            </span>

                            <p
                                onClick={() => navigatePerkViewAll()}
                                className="font-semibold cursor-pointer not-heading-font leading-[150%] tracking-[-0.02rem] underline underline-offset-2 text-14 text-rocket">
                                {t('global.view-all')}
                            </p>
                        </div>

                        <div className="grid lg-gap-8 md:grid-cols-2 grid-cols-2 lg:gap-8 gap-4 mt-3">
                            {homePage?.perkCollection?.perks?.slice(0, 2)?.map((item) => (
                                <div key={item.id}>
                                    <PerkCard item={item} type="home" />
                                </div>
                            ))}
                        </div>
                    </div>
                ),
            },
            {
                positionLeft: homepage.freePerkOfTheMonthLeft,
                positionRight: homepage.freePerkOfTheMonthRight,
                position: homePage?.freePerkOfTheMonth ? homepage[`freePerkOfTheMonth${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                            {t('home.swap-of-the-month')}
                        </span>

                        <PerkOfTheMonth item={homePage?.freePerkOfTheMonth} type="home" />
                    </div>
                ),
            },
            {
                positionLeft: homepage.featuredArticlesLeft,
                positionRight: homepage.featuredArticlesRight,
                position: homePage?.featuredArticles ? homepage[`featuredArticles${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <DiscoverCard item={homePage?.featuredArticles} />
                    </div>
                ),
            },
            {
                positionLeft: homepage.aRecipeWeLoveLeft,
                positionRight: homepage.aRecipeWeLoveRight,
                position: homePage?.aRecipeWeLove ? homepage[`aRecipeWeLove${columnName}`] : null,
                component: (
                    <div className="max-mobile:px-4">
                        <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                            {t('home.a-recipe-we-love')}
                        </span>

                        <HomeRecipeWeLoveCard item={homePage?.aRecipeWeLove} />
                    </div>
                ),
            },
            {
                positionLeft: homepage.pledgeCollectionLeft,
                positionRight: homepage.pledgeCollectionRight,
                position: homePage?.pledgeCollection?.pledges ? homepage[`pledgeCollection${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <div className="flex items-end justify-between">
                            <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                                {homePage?.pledgeCollection?.title}
                            </span>

                            <p
                                onClick={() => navigatePledgesViewAll()}
                                className="font-semibold cursor-pointer not-heading-font leading-[150%] tracking-[-0.02rem] underline underline-offset-2 text-14 text-rocket">
                                {t('global.view-all')}
                            </p>
                        </div>

                        <div className="grid lg-gap-8 md:grid-cols-2 grid-cols-2 lg:gap-8 gap-4 mt-3">
                            {homePage?.pledgeCollection?.pledges?.slice(0, 2)?.map((item) => (
                                <div key={item.id} className="">
                                    <PledgesCard item={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                ),
            },
            {
                positionLeft: homepage.recipeCollectionLeft,
                positionRight: homepage.recipeCollectionRight,
                position: homePage?.recipeCollection?.recipe ? homepage[`recipeCollection${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <div className="flex items-end justify-between">
                            <span className="heading-uppercase heading-font heading-font font-medium tracking-tight text-black text-18">
                                {homePage?.recipeCollection?.name}
                            </span>

                            <p onClick={() => navigateRecipeViewAll()}
                                className="font-semibold cursor-pointer not-heading-font tracking-tight underline underline-offset-2 text-14 text-spinach">
                                {t('global.view-all')}
                            </p>
                        </div>

                        <div className="grid lg-gap-8 md:grid-cols-2 grid-cols-2 lg:gap-8 gap-4 mt-3">
                            {homePage?.recipeCollection?.recipe?.slice(0, 2)?.map((item) => (
                                <div key={item.id}>
                                    <RecipeCard item={item} type="home" />
                                </div>
                            ))}

                            {homePage?.recipeCollection?.recipe?.slice(2, 4)?.map((item) => (
                                <div key={item.id}>
                                    <RecipeCard item={item} type="home" />
                                </div>
                            ))}
                        </div>
                    </div>
                ),
            },
            {
                positionLeft: homepage?.leaderboardRight,
                positionRight: homepage?.leaderboardLeft,
                position: homepage[`leaderboard${columnName}`],
                component: (
                    user?.company?.gamification && prizeDraw?.showLeaderboard && !isEmpty(switchData) ? (
                        <div>
                            <div className={`flex items-end justify-between ${screenWidth < 768 && 'px-4'}`}>
                                <span
                                    className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                                    {switchData?.length > 1 ? t('global.leaderboards') : t('global.leaderboard')}
                                </span>

                                <p
                                    onClick={() => navigate('me/leaderboard')}
                                    className="font-semibold cursor-pointer tracking-tight underline underline-offset-2 text-14 text-rocket not-heading-font">
                                    {t('global.view-all')}
                                </p>
                            </div>

                            {switchData?.length > 1 && (
                                <div className={`mt-3 ${homepage?.leaderboardLeft ? 'grid grid-cols-2 gap-3 mx-2' : `grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 ${screenWidth < 768 ? 'mx-6' : 'mx-2'}`}`}>
                                    {switchData?.map((item, index) => {
                                        const IconComponent = item.icon
                                        const isActive = showSwitch === index

                                        return (
                                            <button key={item?.id} onClick={() => switchSelectorFunc(index, item?.id)}
                                                className={`${isActive ? 'bg-rocket border-rocket border' : 'bg-white border-grey01 border'} flex items-center justify-center w-full h-[32px] rounded-md cursor-pointer`}>
                                                <h1 className={`text-14 ${isActive ? 'text-pistachio' : 'text-grey01'} font-semibold mr-2`}>
                                                    {item?.name}
                                                </h1>

                                                {item?.image ? (
                                                    <img className="object-cover w-[17px] h-[17px]" src={item.image} alt="" />
                                                ) : (
                                                    <IconComponent
                                                        color={isActive ? whiteLabeling?.pistachio : whiteLabeling?.grey01} />
                                                )}
                                            </button>
                                        )
                                    })}
                                </div>
                            )}

                            {leaderboardLoading ? (
                                <div className="flex items-center justify-center h-48">
                                    <Loader width={32} height={32} />
                                </div>
                            ) : (
                                <div className="mt-3">
                                    {!switchData[showSwitch]?.isUserLeaderboard && !user.organisationUnit ? (
                                        <div>You do not have an associated {user.company.organisationalUnitName}</div>
                                    ) : myRangeBelowTwo?.results?.map((item) => {
                                        const isUser = myRangeBelowTwo?.isUserLeaderboard
                                            ? user?.id?.toString() === item?.userId?.toString()
                                            : item?.me

                                        return (
                                            <div
                                                key={item?.userId}
                                                className={`flex items-center justify-between h-[60px] border-b-[1px] border-[#EEFFED] ${isUser ? 'bg-grapeFruit' : 'bg-white'} px-4`}
                                            >
                                                <div className="flex items-center">
                                                    <h1
                                                        className={`mobile:text-16 text-14 font-medium not-heading-font tracking-[-0.02rem] text-black ${isUser ? 'text-white' : 'text-dark'}`}
                                                    >
                                                        {item?.userRank || item?.rank}
                                                    </h1>

                                                    <div className="flex items-center">
                                                        <div>
                                                            {item?.image ? (
                                                                <img className="object-cover w-[40px] h-[40px] ml-[10px] rounded-full" src={item?.image} alt="" />
                                                            ) : (
                                                                <div className="ml-[10px]">
                                                                    {myRangeBelowTwo?.isUserLeaderboard ? (
                                                                        <UserDefaultIcon width={40} height={40} />
                                                                    ) : (
                                                                        leaderboards?.entry?.image
                                                                            ? <img className="object-cover w-[40px] h-[40px]" src={leaderboards?.entry?.image} alt="" />
                                                                            : <UserDefaultIcon width={40} height={40} />
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <h1
                                                            style={{ maxWidth: screenWidth - 190 }}
                                                            className={`ml-[13px] x-mobile:max-w-full x-mobile:line-clamp-none line-clamp-1 font-medium mobile:text-16 text-14 tracking-[-0.01rem] not-heading-font ${isUser ? 'text-white' : 'text-black'}`}
                                                        >
                                                            {myRangeBelowTwo?.isUserLeaderboard ? (
                                                                <div>
                                                                    {item.firstName} {item?.lastName?.slice(0, 1)}.
                                                                </div>
                                                            ) : (
                                                                <div>{item?.name}</div>
                                                            )}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <h1
                                                    className={`font-medium mobile:text-16 text-14 tracking-[-0.01rem] text-dark not-heading-font ${isUser ? 'text-white' : 'text-black'}`}
                                                >
                                                    {item?.thisMonthPoints} {t('global.pts')}.
                                                </h1>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    ) : null
                ),
            },
            {
                positionLeft: homepage?.challengeRight,
                positionRight: homepage?.challengeLeft,
                position: homePage?.challenge?.isActive && !homePage?.challenge?.isJoined ? homepage[`challenge${columnName}`] : 0,
                component: (
                    homePage?.challenge?.isActive && !homePage?.challenge?.isJoined ? (
                        <div className="max-mobile:px-4">
                            <span className="font-medium tracking-tight text-18 text-dark">
                                {t('global.this-month’s-challenge')}
                            </span>

                            <ChallengeCardMini item={homePage?.challenge} home />
                        </div>
                    ) : (
                        percentage !== 100 && (
                            <div>
                                {percentage > 0 && (
                                    <div className="flex items-center mt-8">
                                        <div className="">
                                            <CircularProgressbarWithChildren
                                                className="w-[86px] h-[86px]"
                                                value={percentage}
                                                strokeWidth={7}
                                                styles={{
                                                    trail: { stroke: '#D4F7D2', strokeLinecap: 'inherit', strokeWidth: 5 },
                                                    path: { stroke: '#BA5A4B', strokeLinecap: 'round' },
                                                }}>
                                                <img className="w-[34px] mt-[4px]" src={homePage?.challenge?.activeBadgeIcon} alt="doge" />
                                            </CircularProgressbarWithChildren>
                                        </div>

                                        <div className="ml-4">
                                            <h1 className="text-18 font-medium tracking-tight text-dark">
                                                {t('home.you’ve-completed')} {percentage}% {t('home.of-the')} {homePage?.challenge?.challengeName}
                                            </h1>

                                            <span onClick={() => navigateChallengeDetail(homePage?.challenge?.id)} className="tracking-tight font-semibold cursor-pointer underline underline-offset-3 text-rocket mt-1 text-14">
                                                {t('home.log-your-progress-now')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    )
                ),
            },
            {
                positionLeft: homepage?.quizRight,
                positionRight: homepage?.quizLeft,
                position: homePage?.quiz?.id ? homepage[`quiz${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <QuizCard item={homePage?.quiz} />
                    </div>
                ),
            },
            {
                positionLeft: homepage?.companyArticleRight,
                positionRight: homepage?.companyArticleLeft,
                position: Object.keys(homePage?.companyArticle || {}).length !== 0 ? homepage[`companyArticle${columnName}`] : 0,
                component: (
                    <div className="max-mobile:px-4">
                        <SpecificContentCard item={homePage?.companyArticle} />
                    </div>
                ),
            },
            {
                positionLeft: homepage?.prizeDrawRight,
                positionRight: homepage?.prizeDrawLeft,
                position: homepage[`prizeDraw${columnName}`],
                component: (
                    user?.company?.gamification ? (
                        isEmpty(prizeDraw) ? null : (
                            <div onClick={() => navigate('/prize-draw')} className="max-mobile:px-4 pb-6">
                                <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                                    {t('home.prize-draw-ends')} {daysUntilEndsAt === 0 ? t('global.today') : `${t('home.in')} ${daysUntilEndsAt} ${daysUntilEndsAt === 1 ? t('global.day') : t('global.days')}`}
                                </span>

                                <PrizeDrawCard item={prizeDraw} />
                            </div>
                        )

                    ) : null
                ),
            },
            {
                positionLeft: homepage?.perkPartnersRight,
                positionRight: homepage?.perkPartnersLeft,
                position: homepage[`perkPartners${columnName}`],
                component: (
                    <div className="max-w-[326px] max-mobile:px-4">
                        <div className="flex items-center justify-between">
                            <span className="heading-uppercase heading-font font-medium tracking-tight text-black text-18">
                                {t('home.our-swaps-partners')}
                            </span>

                            <button onClick={() => navigate('/lower/swaps')}
                                className="font-semibold not-heading-font tracking-tight underline underline-offset-2 mt-0.5 text-14 text-rocket cursor-pointer">
                                {t('global.view-all')}
                            </button>
                        </div>

                        <div className="flex mt-5">
                            <div className="cursor-pointer flex justify-between flex-wrap">
                                {(perksBrand.length === 0 ? data : perksBrand)?.slice(0, 6)?.map((item?: IPerks) => (
                                    <button onClick={() => {
                                        if (item) navigatePerkDetail(item?.id)
                                    }} key={item?.id || Math.random()}>
                                        <PerkPartners item={item} />
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                ),
            },
            ...(homePage?.contentBlocks?.map((item): IDashboardColumn => ({
                position: (columnName === 'Left' ? item.left : columnName === 'Right' ? item.right : item.main) || 0,
                positionLeft: item.left || 0,
                positionRight: item.right || 0,
                component: <ContentBlockComponent item={item} />,
                contentBlock: item.contentBlock,

                stickyStyle: {
                    bottom: item.contentBlock.sticky === 'sticky-bottom' ? 0 : undefined,
                    paddingBottom: item.contentBlock.sticky === 'sticky-bottom' ? 0 : undefined,
                    top: item.contentBlock.sticky === 'sticky-top' ? navbarHeight : undefined,
                    zIndex: item.contentBlock.sticky ? 10 : 1,
                },
            })) || []),
        ]
        // @ts-ignore
        return column.filter((obj) => obj.position && obj.position > 0).sort((a, b) => a.position - b.position)
    }

    const columns = {
        left: sortStickyDashboard(columnItems(homePage, 'Left')),
        main: sortStickyDashboard(columnItems(homePage, 'Main')),
        right: sortStickyDashboard(columnItems(homePage, 'Right')),
    }

    const isDomainInTags = typeof user?.company?.embeddedDomains === 'string' && user?.company?.embeddedDomains?.split(',').includes(window?.location?.host)

    const isEmbedded = (navigator?.userAgent === 'lowr-mobile-app' || window?.self !== window?.top) || isDomainInTags

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Home' })
    }, [])

    return (
        <div data-testid="dashboard-container">
            <Layout contentContainerClassName={`justify-center ${(user?.company?.footerIsShown ? columns?.main?.length <= 1 : columns?.main?.length >= 1) && 'max-medium:h-screen'}`} spaceOnBottom={false}>
                <style>
                    {`
                    @media screen and (max-width: 1199px) and (min-width: 912px) {
                        .homepage-content {
                            margin: 0 0 0 40px;
                            flex: 1
                        }
                    }

                    @media screen and (max-width: 911px) and (min-width: 768px) {
                        .homepage-content {
                            margin: 0 0 0px 32px;
                            flex: 1
                        }
                    }

                    @media screen and (min-width: 1200px) {
                        .homepage-content {
                            margin: 0 30px 0 30px;
                        }
                    }
                `}
                </style>

                <div
                    style={styles.dashboardContainer}
                    className={`flex mobile:ml-10 mobile:mr-10 justify-center w-full ${isEmbedded ? 'mb-[0px]' : 'mb-32'} max-w-[1440px]`}>
                    {columns.left.length > 0 && (
                        <div
                            style={{ paddingTop: screenWidth <= 911 ? navbarHeight : navbarHeight + 40, height: screenHeight - navbarHeight, maxWidth: 326 }}
                            className={cn('scrollbar-hide max-mobile:hidden mobile:overflow-y-scroll mobile:sticky mobile:top-0 tablet:min-w-[264px] medium:w-[31%] tablet:w-[23.5%] min-[1417px]:flex-1 mobile:pb-10 !max-mobile:h-auto mobile:w-[33.5%]', css(styles.accountInfoContainer))}>
                            {columns.left.map((i) => (
                                <div style={i.stickyStyle} className={cn({ sticky: i.contentBlock?.sticky })}
                                    key={i.position}>
                                    {i.component}
                                </div>
                            ))}
                        </div>
                    )}

                    <div
                        style={{ paddingTop: screenWidth <= 911 ? navbarHeight : navbarHeight + 40 }}
                        className={cn('tablet:max-w-[628px] tablet:flex-1 medium:max-w-[753px] homepage-content', css(styles.dashboardContent))}>
                        {sponsoredBlock && (<div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>)}

                        {columns.main.map((i) => (
                            <div style={i.stickyStyle} key={i.position} className={cn({
                                'pb-6': !i?.positionRight && !i?.positionLeft,
                                'tablet:hidden pb-6': i?.positionRight,
                                'mobile:hidden pb-6': i?.positionLeft,
                                sticky: i.contentBlock?.sticky,
                            })}>
                                {i.component}
                            </div>
                        ))}
                    </div>

                    {columns.right.length > 0 && (
                        <div style={{ top: navbarHeight, paddingTop: 40, height: screenHeight - navbarHeight }} className="scrollbar-hide max-w-[326px] mobile:overflow-y-scroll sticky top-0 max-tablet:hidden tablet:w-[23.5%] min-[1417px]:flex-1 tablet:min-w-[264px] pb-10">
                            {columns.right.map((i) => (
                                <div style={i.stickyStyle} className={cn('pb-3', { sticky: i.contentBlock?.sticky })}
                                    key={i.position}>
                                    {i.component}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Layout>
        </div>
    )
}
