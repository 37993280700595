import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { ArrowTravel, Cross, DismissIcon } from '../../components/common/Svg'
import Layout from '../../components/layout/Layout'
import { getHomePageLists } from '../../state/home/selectors'
import { Context } from '../../components/common/BaseContext'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import Button from '../../components/common/Button'
import { legTransportList, travelSelector } from '../../state/travel/selectors'
import { ITrackTravelCarType, ITrackTravelPeopleCount } from '../../types/data'
import { getWhiteLabelingList } from '../../state/user/selectors'
import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import { Colors } from '../../themes'
import { legTransportDetails } from '../../state/travel/actions'
import { travelDate } from '../../utils/date'

const peopleData = [
    {
        id: 1,
        peopleCount: 0,
        selected: false,
    },
    {
        id: 2,
        peopleCount: 1,
        selected: false,
    },
    {
        id: 3,
        peopleCount: 2,
        selected: false,
    },
    {
        id: 4,
        peopleCount: 3,
        selected: false,
    },
]

interface FormData {
  countOfPeople: string;
}

interface IProps {
    disableSelecting?: boolean
}

export default function LogTravelCarDetails({ disableSelecting }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = useLocation()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const today = new Date()
    const { isEmbedded, screenWidth, navbarHeight } = useContext(Context)
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const { transportList, loading } = useSelector(travelSelector)
    const { legTransports } = useSelector(legTransportList)
    const [t] = useTranslation('global')

    const carSize = transportList?.find((item) => item?.name?.includes('Car') || item?.name?.includes('Taxi'))
    const carFuel = transportList?.find((item) => item?.name?.includes('Car') || item?.name?.includes('Taxi'))

    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [sizeTypeData, setSizeTypeData] = useState(carSize?.sizes)
    const [fuelTypeData, setFuelTypeData] = useState(carFuel?.fuelTypes)
    const [peopleCount, setPeopleCount] = useState(peopleData)

    const countPeople = peopleCount.find((item) => item.selected)
    const allFuel = fuelTypeData?.find((item) => item?.selected)
    const allSizeCar = sizeTypeData?.find((item) => item?.selected)
    const peopleCountIsActive = peopleCount.find((item) => item.selected)

    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')
    const date = location?.state?.date
    const gameDate = location?.state?.gameDate
    const gameId = location?.state?.gameId
    const postCode = location?.state?.postCode
    const byCar = location?.state?.byCar
    const goingBackCommute = location?.state?.goingBackCommute
    const fromImpact = location?.state?.fromImpact
    const edit = location?.state?.edit
    const impact = location?.state?.impact
    const choosedCar = location?.state?.choosedCar
    const fuel = allFuel
    const sizeCar = allSizeCar

    const storedDataEditString = localStorage.getItem('travelDataEdit')
    // @ts-ignore
    const leg = JSON.parse(storedDataEditString)

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const minDate = outboundData.date.reduce((a: string, b: string) => (new Date(b) < new Date(a) ? b : a))

    const onBack = () => {
        if (fromImpact) {
            navigate(-1)
            return
        }

        navigate('/log/track-travel-modality', { state: { impact, gameDate, gameId, date, postCode, edit, goingBackCommute, byCarBack: byCar, prevUrl: location?.state?.prevUrl } })
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Travel car details' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    const onChangeValueSizeType = (item: ITrackTravelCarType) => {
        const newData = sizeTypeData?.map((newItem) => (newItem.id === item.id
            ? { ...newItem, selected: !newItem.selected }
            : { ...newItem, selected: false }))
        setSizeTypeData(newData)
    }

    const onChangeValueFuelType = (item: ITrackTravelCarType) => {
        const newData = fuelTypeData?.map((newItem) => (newItem.id === item.id
            ? { ...newItem, selected: !newItem.selected }
            : { ...newItem, selected: false }))
        setFuelTypeData(newData)
    }

    const onChangeValuePeopleCount = (item: ITrackTravelPeopleCount) => {
        const newData = peopleCount?.map((newItem) => (newItem.id === item.id
            ? { ...newItem, selected: !newItem.selected }
            : { ...newItem, selected: false }))
        // @ts-ignore
        setPeopleCount(newData)
    }

    useEffect(() => {
        if (edit && !leg.hideSelectedValues) {
            const newData = sizeTypeData?.map((newItem) => (newItem?.name === leg?.transportSize?.name
                ? { ...newItem, selected: !newItem.selected }
                : { ...newItem, selected: false }))
            // @ts-ignore
            setSizeTypeData(newData)
        }
        // eslint-disable-next-line
    }, [edit])

    useEffect(() => {
        if (edit && !leg.hideSelectedValues) {
            const newData = fuelTypeData?.map((newItem) => (newItem?.name === leg?.transportFuelType?.name
                ? { ...newItem, selected: !newItem.selected }
                : { ...newItem, selected: false }))
            // @ts-ignore
            setFuelTypeData(newData)
        }
        // eslint-disable-next-line
    }, [edit])

    useEffect(() => {
        if (edit && peopleCount && !leg.hideSelectedValues) {
            const newData = peopleCount.map((newItem) => (
                newItem?.peopleCount === leg?.countOfPeople
                    ? { ...newItem, selected: !newItem.selected }
                    : { ...newItem, selected: false }
            ))

            if (leg?.countOfPeople && leg.countOfPeople > 3) {
                const lastIndex = peopleCount.length - 1
                newData[lastIndex] = { ...newData[lastIndex], selected: true }
            }

            // @ts-ignore
            setPeopleCount(newData)
        }
        // eslint-disable-next-line
    }, [edit])

    const initialValues: FormData | undefined = {
        countOfPeople: edit ? leg?.countOfPeople : '',
    }

    const onSubmit = (value: FormData = { countOfPeople: '' }) => {
        const { countOfPeople } = value
        const legTransportDetailsData = {
            leg: leg?.backendLegId || legTransports?.id,
            transportSize: sizeCar?.id,
            transportFuelType: fuel?.id,
            countOfPeople: peopleCount.find((item) => item.selected && item.peopleCount === 3) ? countOfPeople : countPeople?.peopleCount,
        }

        dispatch(legTransportDetails(legTransportDetailsData, () => {
            navigate('/log/track-travel-leg-start-end', { state: { impact, gameDate, gameId, date, fuel, postCode, sizeCar, byCar, choosedCar, countOfPeople: peopleCount.find((item) => item.selected && item.peopleCount === 3) ? countOfPeople : countPeople?.peopleCount, edit, goingBackCommute, fromImpact, prevUrl: location?.state?.prevUrl } })
        }))
    }

    const isDisabled = loading || disableSelecting

    useEffect(() => {
        if ((allFuel && allSizeCar && peopleCountIsActive) && (!isDisabled && peopleCountIsActive.peopleCount !== 3)) {
            onSubmit()
        }
        // eslint-disable-next-line
    }, [allFuel, allSizeCar, peopleCountIsActive])

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded}>
            <div className="w-full h-screen">
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    <Form>
                        <div className="flex flex-col items-center h-screen">
                            <div className="w-full min-h-screen">
                                <div style={{ marginTop: navbarHeight, left: '50%', transform: 'translateX(-50%)' }}
                                    className="fixed top-0 left-0 w-full z-10 bg-white max-w-[1440px]">
                                    {sponsoredBlock && <div className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                                    <div className="flex justify-between items-center h-[53px] pr-2 mobile:mx-[42px] mx-4">
                                        <div onClick={onBack} className="flex justify-end cursor-pointer">
                                            <ArrowTravel width={22} height={22} />
                                        </div>

                                        <div onClick={() => setShowQuizQuitOverlayModal(true)} className="flex justify-end cursor-pointer">
                                            <DismissIcon width={18} height={18} />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : !sponsoredBlock ? navbarHeight : 125 }} className="h-screen flex flex-col items-center mobile:px-[42px] px-4">
                                    <div className="max-w-[600px] w-full pb-[300px]">
                                        <h1 className="heading-uppercase text-22 mt-2 font tracking-tight font-semibold text-cavolo">
                                            {t('log.car-details')}
                                        </h1>

                                        <h1 className="text-16 font-medium not-heading-font tracking-tight text-cavolo mt-5">
                                            {t(travelDate(today) >= minDate ? 'log.what-size-was-the-vehicle?' : 'log.FUTURE|what-size-was-the-vehicle?')}
                                        </h1>

                                        <div className="flex flex-wrap mt-2">
                                            {sizeTypeData?.map((item) => (
                                                <button disabled={isDisabled} style={{ opacity: isDisabled ? 0.5 : 1 }} type="button" key={item?.id} onClick={() => onChangeValueSizeType(item)}
                                                    className={`border-2 ${item?.selected && !isDisabled ? 'border-cavolo' : 'border-spinach'} h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 ${item?.selected && !isDisabled ? 'px-3 bg-cavolo' : 'px-5 bg-pistachio'}`}>

                                                    <span className={`text-14 font-semibold not-heading-font ${item?.selected && !isDisabled ? 'text-white' : 'text-cavolo'} hover:font-bold hover:ease-in hover:duration-200`}>
                                                        {item.name}
                                                    </span>

                                                    {item.selected && (
                                                        <div className="ml-1.5">
                                                            <Cross color={whiteLabeling?.white} width={10} height={10} />
                                                        </div>
                                                    )}
                                                </button>
                                            ))}
                                        </div>

                                        <h1 className="text-16 font-medium not-heading-font tracking-tight text-cavolo mt-5">
                                            {t(travelDate(today) >= minDate ? 'log.what-type-of-fuel-did-the-vehicle-use?' : 'log.FUTURE|what-type-of-fuel-did-the-vehicle-use?')}
                                        </h1>

                                        <div className="flex flex-wrap mt-2">
                                            {fuelTypeData?.map((item) => (
                                                <button disabled={isDisabled} style={{ opacity: isDisabled ? 0.5 : 1 }} type="button" key={item?.id} onClick={() => onChangeValueFuelType(item)}
                                                    className={`border-2 ${item?.selected && !isDisabled ? 'border-cavolo' : 'border-spinach'} h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 ${item?.selected && !isDisabled ? 'px-3 bg-cavolo' : 'px-5 bg-pistachio'}`}>

                                                    <span className={`text-14 font-semibold not-heading-font ${item?.selected && !isDisabled ? 'text-white' : 'text-cavolo'} hover:font-bold hover:ease-in hover:duration-200`}>
                                                        {item.name}
                                                    </span>

                                                    {item.selected && !isDisabled && (
                                                        <div className="ml-1.5">
                                                            <Cross color={whiteLabeling?.white} width={10} height={10} />
                                                        </div>
                                                    )}
                                                </button>
                                            ))}
                                        </div>

                                        <h1 className="text-16 font-medium not-heading-font tracking-tight text-cavolo mt-5">
                                            {t(travelDate(today) >= minDate ? 'log.how-many-other-people-were-in-the-vehicle-with-you?' : 'log.FUTURE|how-many-other-people-were-in-the-vehicle-with-you?')}
                                        </h1>

                                        <div className="flex flex-wrap mt-2">
                                            {peopleCount?.map((item) => (
                                                // @ts-ignore
                                                <button disabled={isDisabled} style={{ opacity: isDisabled ? 0.5 : 1 }} type="button" key={item?.id} onClick={() => onChangeValuePeopleCount(item)}
                                                    className={`border-2 ${item?.selected && !isDisabled ? 'border-cavolo' : 'border-spinach'} h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer mt-[8px] mb-[4px] mr-2 ${item?.selected && !isDisabled ? 'px-[25px] bg-cavolo' : 'px-[30px] bg-pistachio'}`}>

                                                    <span className={`text-14 font-semibold not-heading-font ${item?.selected && !isDisabled ? 'text-white' : 'text-cavolo'} hover:font-bold hover:ease-in hover:duration-200`}>
                                                        {item?.peopleCount} {item.peopleCount === 3 && '+'}
                                                    </span>

                                                    {item.selected && !isDisabled && (
                                                        <div className="ml-1.5">
                                                            <Cross color={whiteLabeling?.white} width={10} height={10} />
                                                        </div>
                                                    )}
                                                </button>
                                            ))}
                                        </div>

                                        {peopleCount.find((item) => item.selected && !isDisabled && item.peopleCount === 3) && (
                                            <div className="mt-[20px]">
                                                <Input
                                                    validate={required(t)}
                                                    name="countOfPeople"
                                                    placeholder={t('log.enter-the-number-of-people')}
                                                    type="number" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* {allFuel && allSizeCar && peopleCountIsActive ? ( */}
                            <div className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`} style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                                <div className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                                    <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                                    <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                        <Button
                                            loading={isDisabled}
                                            disabled={!sizeCar || !fuel || !countPeople}
                                            style={!sizeCar || !fuel || !countPeople ? disabledStyles : undefined}
                                            className="absolute bottom-8 duration-100 not-heading-font"
                                            text={t('global.continue')} />
                                    </div>
                                </div>
                            </div>
                            {/* ) : null} */}
                        </div>
                    </Form>
                </Formik>
            </div>

            <QuizQuitOverlayModal
                description="If you quit now, you’ll lose your trip details and you won’t receive any points!"
                isShown={showQuizQuitOverlayModal}
                onClose={() => (isEmbedded ? navigate('/') : navigate(location?.state?.prevUrl || '/'))}
                onContinue={() => setShowQuizQuitOverlayModal(false)} />
        </Layout>
    )
}
